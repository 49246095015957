@import "src/config";

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.isLoading {
    pointer-events: none;
    opacity: 0.5;
}

.fieldTitle {
 padding: 0 10px ;
}

.fieldAlias {
  padding: 0 15px ;
  font-style: italic;
  font-size: small;
  color: $gray-500;
}

.fieldProcessingStatus,
.fieldProcessingStatusError {
  padding: 0 0 0 15px;
  font-style: italic;
  font-weight: bold;
}
.fieldProcessingStatus {
  color: $blue;
}
.fieldProcessingStatusError {
  color: $red;
}

.fieldDuration,
.fieldType {
  text-align: center;
}

.fieldActions {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.grayedout {
  opacity: 0.5;
  cursor: default;
}

.button {
    display: flex;
    align-items: center;
    gap: 4px;
}

.moderationTriggeredCategories {
    border-left: solid 2px $orange;
}
.moderationTriggeredCategoriesTitle {
    font-weight: bold;
    padding: 2px 4px;
}
.moderationTriggeredCategoriesList {
    display: flex;
    flex-direction: column;
    padding-left: 16px;
}
